import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  "loading": false,
  "user": null,
  "method": "jwt",
  "tokens": null,
  "permissions": null,
  balances: null,
  limits: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    restore: (state, action) => {

    },
    logout(state) {

    },
    login(state, payload) {

    },
    setUser(state, action) {
      const {user, tokens, permissions, balances, limits} = action.payload
      state.user = user
      state.permissions = permissions
      state.tokens = tokens
      state.balances = balances
      state.limits = limits
    },
    setLoading(state, action) {
      const {payload} = action
      state.loading = payload
    },
  },
})

const userReducer = userSlice.reducer

export {userSlice, userReducer}
