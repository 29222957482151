import {m} from 'framer-motion';
// @mui
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
// utils
import {fToNow} from 'src/utils/format-time';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {dispatch} from '../../store/store'
import {endpointSlice} from '../../store/endpoint/endpoingSlice'
import {useNavigate} from 'react-router-dom'

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const popover = usePopover();
  const {endpoints, selectedEndpoint} = useSelector(store => store.endpoint)
  const navigate = useNavigate()

  const selectEndpoint = (endpoint) => {
    dispatch(endpointSlice.actions.selectEndpoint(endpoint))
    popover.onClose()
    navigate('/operations')
  }

  return (
    <>
      {/*{selectedEndpoint && (*/}
      {/*  <Box>*/}
      {/*    <ListItemText*/}
      {/*      primary={selectedEndpoint.name}*/}
      {/*      secondary={selectedEndpoint.short_name}*/}
      {/*      primaryTypographyProps={{typography: 'subtitle2'}}*/}
      {/*      secondaryTypographyProps={{*/}
      {/*        typography: 'caption',*/}
      {/*        color: 'text.disabled',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*)}*/}

      <Button
        variant="link"
        onClick={popover.onOpen}
        // endIcon={<Iconify icon="solar:users-group-rounded-bold-duotone" width={24}/>}
        sx={{
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Box sx={{textAlign: 'right'}}>
          <ListItemText
            primary={selectedEndpoint.name}
            secondary={selectedEndpoint.short_name}
            primaryTypographyProps={{typography: 'subtitle2'}}
            secondaryTypographyProps={{
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </Box>
      </Button>

      {/*<IconButton*/}
      {/*  component={m.button}*/}
      {/*  whileTap="tap"*/}
      {/*  whileHover="hover"*/}
      {/*  variants={varHover(1.05)}*/}
      {/*  color={popover.open ? 'inherit' : 'default'}*/}
      {/*  onClick={popover.onOpen}*/}
      {/*  sx={{*/}
      {/*    ...(popover.open && {*/}
      {/*      bgcolor: (theme) => theme.palette.action.selected,*/}
      {/*    }),*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Iconify icon="solar:users-group-rounded-bold-duotone" width={24}/>*/}
      {/*</IconButton>*/}

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 320, overflow: "auto"}}>
        {/*<Typography variant="h6" sx={{p: 1.5}}>*/}
        {/*  Contacts <Typography component="span">({endpoints.length})</Typography>*/}
        {/*</Typography>*/}

        <Scrollbar>
          {endpoints.data.map((endpoint) => (
            <MenuItem key={endpoint.id} sx={{p: 1}}>
              {/*<Badge*/}
              {/*  variant={contact.status}*/}
              {/*  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}*/}
              {/*  sx={{mr: 2}}*/}
              {/*>*/}
              {/*  <Avatar alt={contact.name} src={contact.avatarUrl}/>*/}
              {/*</Badge>*/}

              <ListItemText
                primary={endpoint.name}
                secondary={endpoint.short_name}
                primaryTypographyProps={{typography: 'subtitle2'}}
                secondaryTypographyProps={{
                  typography: 'caption',
                  color: 'text.disabled',
                }}
                onClick={() => selectEndpoint(endpoint)}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
