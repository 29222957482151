import {put, takeLatest, select} from "redux-saga/effects"
import {registersSlice} from './registersSlice'
import {network} from '../../utils/network'
import {appSlice} from "../app/appSlice"

function* fetch({payload}) {
  yield put(registersSlice.actions.setLoading({type: 'registers', loading: true}))

  try {
    const {selectedEndpoint} = yield select(store => store.endpoint)

    const response = yield network.axios({
      method: 'GET',
      url: '/bid-registry/',
      params: {
        page: payload.page,
        endpoint_id: selectedEndpoint.id,
      }
    })

    yield put(registersSlice.actions.setRegisters({
      data: response.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(registersSlice.actions.setLoading({type: 'registers', loading: false}))
  }
}


function* create({payload}) {
  const formData = new FormData()
  yield put(registersSlice.actions.setLoading({type: 'createRegistry', loading: true}))
  yield put(registersSlice.actions.setCreateRegistry({data: null}))

  const {selectedEndpoint} = yield select(store => store.endpoint)

  formData.append("endpoint_id", selectedEndpoint.id);
  formData.append("document_number", payload.data.document_number);
  formData.append("file", payload.data.file);

  try {

    const response = yield network.axios({
      method: 'POST',
      url: '/bid-registry/',
      data: formData,
      headers: {"Content-Type": "multipart/form-data"}
    })

    yield put(registersSlice.actions.setCreateRegistry({
      data: response?.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(registersSlice.actions.setLoading({type: 'createRegistry', loading: false}))
  }
}

function* fetchId({payload}) {
  yield put(registersSlice.actions.setLoading({type: 'selectedRegistry', loading: true}))
  yield put(registersSlice.actions.setSelectedRegistry({data: null}))

  try {
    const response = yield network.axios({
      method: 'GET',
      url: `/bid-registry/${payload.id}/`,
    })

    yield put(registersSlice.actions.setSelectedRegistry({
      data: response?.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(registersSlice.actions.setLoading({type: 'selectedRegistry', loading: false}))
  }
}

function* approve({payload}) {
  const {data, id} = payload
  yield put(registersSlice.actions.setLoading({type: 'approve', loading: true}))

  const formData = new FormData()
  formData.append("code", data?.code);
  formData.append("id", payload.id);

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid-registry/${id}/withdrawal/`,
      headers: {
        'Content-Type': 'multipart/form-data;'
      },
      data: data?.code ? formData : undefined
    })

    yield put(registersSlice.actions.setApprove({
      data: {
        ...response.data
      },
    }))
    if (response?.data?.is_approved || !response?.data?.is_approve_code_required) {
      yield put(appSlice.actions.addMessage({
        type: 'success',
        text: 'Операция подтверждена',
      }))
    }

  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e.response.data.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(registersSlice.actions.setLoading({type: 'approve', loading: false}))
  }
}


export function* registersSagas() {
  yield takeLatest(registersSlice.actions.fetch.type, fetch)
  yield takeLatest(registersSlice.actions.fetchId.type, fetchId)
  yield takeLatest(registersSlice.actions.create.type, create)
  yield takeLatest(registersSlice.actions.approve.type, approve)
}
