import {m} from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import {useRouter} from 'src/routes/hooks';
// auth
import {useAuthContext} from 'src/auth/hooks';
// components
import {varHover} from 'src/components/animate';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {useSelector} from 'react-redux'
import UserFillIcon from 'src/assets/icons/user-fill-icon'


export default function AccountPopover(props) {

  const {smDown} = props

  const router = useRouter();

  const userName = useSelector(store => store.user?.permissions?.user_data?.name)

  const {logout} = useAuthContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 44,
          height: 44,
          borderRadius: 1,
          background: "#F4F6F8",
        }}
      >
        <UserFillIcon />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 200, p: 0, mt: smDown ? 2.5 : 1.5}}>
        {userName && userName.length && (
          <>
            <Box sx={{p: 2, pb: 1.5}}>
              <Typography variant="subtitle2" color='text.primary' noWrap>
                {userName}
              </Typography>

              <Typography variant="body2" color='text.tertiary' fontSize={12} noWrap>
                Пользователь
              </Typography>
            </Box>

            <Box px={2}>
              <Divider />
            </Box>
          </>
        )}

        <MenuItem
          onClick={handleLogout}
          sx={{m: 1, fontWeight: 'fontWeightBold', color: 'error.main'}}
        >
          Выйти
        </MenuItem>
      </CustomPopover >
    </>
  );
}
