import Stack from '@mui/material/Stack'

export default function AuthClassicLayout({children}) {
  const renderContent = (
    <div style={{background: '#fff', width: '100%'}}>
      <Stack
        sx={{
          width: 1,
          mx: 'auto',
          maxWidth: 580,
          px: {xs: 2, md: 8},
          py: {xs: 15, md: 30},
        }}
      >
        {children}
      </Stack>
    </div>
  )

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderContent}
    </Stack>
  );
}
